import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchEmails, fetchAllEmails, fetchUnreadEmails } from '../../actions/emailActions';
import EmailList from './emailList';
import Header from './Header';

class EmailListContainerForSubscription extends Component {
    componentDidMount() {

        if (this.props.match.path === '/emails/all/') { this.props.fetchAllEmails() }
        if (this.props.match.path === '/emails/unread/') { this.props.fetchUnreadEmails() }
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if (this.props.match.path === '/emails/all/') { this.props.fetchAllEmails() }
            if (this.props.match.path === '/emails/unread/') { this.props.fetchUnreadEmails() }
        }
    }

    render() {
        return (
            <Fragment>
                <Header text={this.props.headerText} />
                <div className="ui hidden section divider"></div>
                <div className="row">
                    <div id="email-list-container" className="ui items">
                        <EmailList emails={this.props.emails} />
                    </div>
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state, ownProps) => {

    return {
        emails: state.emails,
        selectedSubscription: state.selectedSubscription,
        headerText: state.headerText
    };
}
export default connect(mapStateToProps, { fetchEmails, fetchAllEmails, fetchUnreadEmails })(EmailListContainerForSubscription);