import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { signout } from '../actions/auth';

class MenuBar extends Component {
    renderLinks() {
        if (this.props.isSignedIn) {
            const { readerEmail } = this.props.currentUser;
            return (
                <Fragment>
                    <span className="item">{readerEmail}</span>
                    <a className="item" onClick={this.props.signout}>Sign out</a>
                </Fragment>
            );
        } else {
            const port = process.env.NODE_ENV === "development" ? ":3000" : "";
            return <a className="item" href={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SERVER_HOST}${port}/auth/google`}>Google Signin</a>
        }
    }

    render() {
        return (
            <Fragment>
                <div className="ui tablet computer only padded grid">
                    <div className="ui inverted borderless top fixed fluid menu">
                        <a className="header item">ProductFrogs Reader <div className="ui horizontal label">Beta</div></a>
                        
                        <div className="right menu">
                            {this.renderLinks()}
                        </div>
                    </div>
                </div>
                <div className="ui mobile only padded grid">
                    <div className="ui top fixed borderless fluid inverted menu">
                        <a className="header item">ProductFrogs Reader</a>
                        <div className="right menu">
                            <div className="item">
                                <button className="ui icon toggle basic inverted button">
                                    <i className="content icon"></i>
                                </button>
                            </div>
                        </div>
                        <div className="ui vertical borderless inverted fluid menu">
                            <div className="ui fitted divider"></div>
                            {this.renderLinks()}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    const { isSignedIn, currentUser } = state.auth;
    return {
        isSignedIn,
        currentUser
    }
}
export default connect(mapStateToProps, { signout })(MenuBar);