import React, { Component } from 'react';
import SplitViewWithSubscriptionsLayout from './components/layouts/splitViewWithSubscriptionsLayout';
import SigninCallback from './components/auth/signinCallback';
import MenuBar from './components/menuBar';
import Home from './components/layouts/home';
import EmailListContainerForSubscription from './components/emails/emailListContainerForSubscription';
import { getUserInfo } from './actions/auth';
import './App.css';
import { connect } from 'react-redux';
import {
  Router,
  Switch,
  Route
} from "react-router-dom";
import history from './utilities/history';
import EmailShow from './components/emails/emailShow';

class App extends Component {
  componentDidMount() {
    this.props.getUserInfo();
  }

  render() {
    return (
      !this.props.isLoadding && <Router history={history} >
        <div className="App">
          <div className="ui padded grid">
            <MenuBar />

            <Switch>
              <Route path="/" exact component={Home} />
              <RouteWrapper path="/subscriptions/" exact component={EmailListContainerForSubscription} layout={SplitViewWithSubscriptionsLayout} />
              <RouteWrapper path="/subscriptions/:publicSubscriptionId" exact component={EmailListContainerForSubscription} layout={SplitViewWithSubscriptionsLayout}/>
              <RouteWrapper path="/emails/all/" exact component={EmailListContainerForSubscription} layout={SplitViewWithSubscriptionsLayout}/>
              <RouteWrapper path="/emails/unread/" exact component={EmailListContainerForSubscription} layout={SplitViewWithSubscriptionsLayout}/>
              <RouteWrapper path="/emails/:publicEmailId" exact component={EmailShow} layout={SplitViewWithSubscriptionsLayout} />
              <Route path="/signinCallback" exact component={SigninCallback} />
            </Switch>
          </div>
        </div>
      </Router >
    );
  }
}

function RouteWrapper({
  component: Component,
  layout: Layout,
  ...rest
}) {
  return (
    <Route {...rest} render={(props) =>
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    } />
  );
}

const mapStateToProps = (state) => {
  return { isLoading: state.auth.isLoading }
}

export default connect(mapStateToProps, { getUserInfo })(App);
