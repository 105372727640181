import React, { Component } from 'react';
import SubscriptionItem from './subscriptionItem';
import { connect } from 'react-redux';
import { fetchSubscriptionsAndSelect, fetchSubscriptionsAndSelectFirst, selectSubscriptionAndFetchEmails, fetchSubscriptions } from '../../actions/subscriptionActions';
import { NavLink } from 'react-router-dom';


class SubscriptionList extends Component {
    componentDidMount() {
        const { publicSubscriptionId, publicEmailId } = this.props.match.params;
        // path /subscriptions/:id --> on fetche les subs et on selectionne la sub id
        if (publicSubscriptionId) { this.props.fetchSubscriptionsAndSelect(publicSubscriptionId) }
        // path /emails/all
        else if (this.props.match.path === '/emails/all/' || this.props.match.path === '/emails/unread/') { this.props.fetchSubscriptions() }
        // path /emails/all
        else if (this.props.match.path === '/emails/unread/') { this.props.fetchSubscriptions() }
        // path /subscriptions/ --> on fetche les subs et on selectionne la première sub
        else if (!publicSubscriptionId && !publicEmailId) { this.props.fetchSubscriptionsAndSelectFirst() }
        
    }

    componentDidUpdate(prevProps) {
        const { publicSubscriptionId, publicEmailId } = this.props.match.params;

        if (this.props.location !== prevProps.location) {
            //si on veut afficher l'email list d'une subscription
            if (publicSubscriptionId) {
                this.props.selectSubscriptionAndFetchEmails(publicSubscriptionId);
            }
        }
    }

    renderList() {
        const {path} = this.props.match;
        return this.props.subscriptions.map(subscription => {
            return (
                <SubscriptionItem subscription={subscription}
                    key={`${subscription.fromEmail}`}
                    active={path !== '/emails/all/' && path !== '/emails/unread/' && this.props.selectedSubscription && (this.props.selectedSubscription.publicSubscriptionId === subscription.publicSubscriptionId)}

                />
            );
        });

    }

    render() {
        return (
            <div
                className="three wide tablet only three wide computer only column"
                id="sidebar"
            >
                <div className="ui vertical borderless fluid text menu">
                    <NavLink to="/emails/all/" activeClassName="active" className="item">All</NavLink>
                    <NavLink to="/emails/unread/" activeClassName="active" className="item">Unread</NavLink>
                    {/* <a className="item">Favorites</a>
                    <a className="item">Links</a> */}
                    <div className="ui hidden divider"></div>
                    {this.renderList()}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        subscriptions: state.subscriptions,
        selectedEmail: state.selectedEmail,
        selectedSubscription: state.selectedSubscription
    };
}


export default connect(mapStateToProps, { fetchSubscriptionsAndSelect, fetchSubscriptionsAndSelectFirst, selectSubscriptionAndFetchEmails, fetchSubscriptions })(SubscriptionList);