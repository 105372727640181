import {
    FETCH_EMAILS,
    SELECT_EMAIL,
    EMAIL_MARKED_AS_READ,
    SET_HEADER_TEXT
} from '../actions//types';

export const emails = (state = [], action) => {
    switch (action.type) {
        case FETCH_EMAILS:
            return action.payload;
        case EMAIL_MARKED_AS_READ:
            return state.map(email => {
                return email.publicEmailId === action.payload.publicEmailId ? action.payload : email;
            });
        default:
            return state;
    }
}

export const selectedEmail = (state = {}, action) => {
    switch (action.type) {
        case SELECT_EMAIL:
            return action.payload;
        default:
            return state;
    }
}

export const headerText = (state = "", action) => {
    switch (action.type) {
        case SET_HEADER_TEXT:
            return action.payload;
        default:
            return state;
    }
}