import {
    FETCH_SUBSCRIPTIONS,
    SELECT_SUBSCRIPTION,
    SELECT_EMAIL
} from './types';
import apiServer from '../apis/apiServer';
import { fetchEmails, setHeaderText, fetchEmailHeaders, deselectEmail } from './emailActions';
import _ from 'lodash';
import history from '../utilities/history';


export const fetchSubscriptions = () =>
    (dispatch, getState) =>
        apiServer.get(`/subscriptions/list`)
            .then(response => dispatch({ type: FETCH_SUBSCRIPTIONS, payload: response.data }))
            .catch(e => console.log(e));

export const selectSubscription = (subPublicId) =>
    (dispatch, getState) => {
        if (subPublicId) {
            const sub = _.find(getState().subscriptions, { 'publicSubscriptionId': subPublicId });
            // dispatch(deselectEmail());
            return Promise.resolve(dispatch({ type: SELECT_SUBSCRIPTION, payload: sub }))
                .then(dispatch(setHeaderText(sub.fromText)));
        }
    };

export const selectSubscriptionAndFetchEmails = (subId) =>
    (dispatch, getState) =>
        dispatch(() => dispatch(selectSubscription(subId)))
            .then(() => dispatch(fetchEmails(subId)))

export const fetchSubscriptionsAndSelect = (subId) =>
    (dispatch) =>
        dispatch(fetchSubscriptions())
            .then(() => dispatch(selectSubscription(subId)))
            .then(() => dispatch(fetchEmails(subId)))



export const fetchSubscriptionsAndSelectFirst = function () {

    return function (dispatch, getState) {
        return dispatch(fetchSubscriptions())
            .then((response) => {
                dispatch(selectSubscription(response.payload[0].publicSubscriptionId))
                    .then(() => dispatch(fetchEmails(response.payload[0].publicSubscriptionId)))
            })
    }
}


