import {
    FETCH_SUBSCRIPTIONS,
    SELECT_SUBSCRIPTION,
    UPDATE_SUBSCRIPTION
} from '../actions//types';

export const subscriptions = (state = [], action) => {
    switch (action.type) {
        case FETCH_SUBSCRIPTIONS:
            return action.payload;
        case UPDATE_SUBSCRIPTION:
            return state.map(sub => {
                return sub.publicSubscriptionId === action.payload.publicSubscriptionId ? action.payload : sub;
            });
        default:
            return state;
    }
}

export const selectedSubscription = (state = {}, action) => {
    switch (action.type) {
        case SELECT_SUBSCRIPTION:
            return action.payload;
        default:
            return state;
    }
}