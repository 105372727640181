import React from 'react';
import {connect} from 'react-redux';
import {getUserInfo} from '../../actions/auth';
import history from '../../utilities/history';

class SigninCallback extends React.Component {
    componentDidMount(){
        this.props.getUserInfo();
        history.push("subscriptions/");
    }

    render(){
        return(
            <div className="ui segment">Chargement...</div>
        );
    }
}

export default connect(null, {getUserInfo})(SigninCallback);