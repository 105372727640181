export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const SELECT_SUBSCRIPTION = 'SELECT_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';

export const FETCH_EMAILS = 'FETCH_EMAILS';
export const SELECT_EMAIL = 'SELECT_EMAIL';
export const EMAIL_MARKED_AS_READ = 'EMAIL_MARKED_AS_READ';
export const SET_HEADER_TEXT = 'SET_HEADER_TEXT';


export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';