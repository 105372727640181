import React , {Fragment} from 'react';
import SubscriptionsList from '../subscriptions/subscriptionList';
import EmailListHeader from '../emails/Header';

const SplitViewWithSubscriptionsLayout = ({match,location, children}) => {

    return (
        <Fragment>
            <SubscriptionsList match={match} location={location}/>
            <div id="content" className="sixteen wide mobile thirteen wide tablet thirteen wide computer right floated column">
                <div className="ui padded grid">
                    {children}
                </div>
            </div>
        </Fragment>
    );
};
export default SplitViewWithSubscriptionsLayout;