import React from 'react';


const Header = ( {text} ) => {
    return (
        <div className="row">
            <h1 className="ui huge dividing header">{text}</h1>
        </div>
    );
}

export default Header;