import { AUTH_USER, UNAUTH_USER, AUTH_ERROR, SELECT_EMAIL, SELECT_SUBSCRIPTION } from './types';
import apiServer from '../apis/apiServer';
import history from '../utilities/history';


// //on attend un objet qui a les prop email et password
// export const signin = (formValues, cb=()=>{}) => async dispatch => {
//   try {
//     const response = await apiServer.post('/signin', formValues);
//     dispatch({ type: AUTH_USER, payload: response.data });
//     cb();
//   } catch (e) {
//     dispatch({ type: AUTH_ERROR, payload: "Email / mot de passe invalides" });
//     dispatch({ type: SET_INFO_MESSAGE, payload: {type:'error', 
//         header:"Email / mot de passe invalides", 
//         text:"Votre email ou votre mot de passe sont invalides. Veuillez essayer à nouveau s'il vous plait.",
//         timestamp: Date.now()} });
//   }

// }

// export const signup = (formValues, cb=()=>{}) => async dispatch => {
//   try {
//     const response = await apiServer.post('/signup', formValues);
//     dispatch({ type: AUTH_USER, payload: response.data });
//     cb();
//     dispatch({ type: SET_INFO_MESSAGE, payload: {type:'success', 
//         header:"Bienvenu !", 
//         text:"Vous êtes maintenant inscrit.e correctement"} });
//   } catch (e) {
//     dispatch({ type: AUTH_ERROR, payload: "Cet email déjà utilisé" });
//     dispatch({ type: SET_INFO_MESSAGE, payload: {type:'error', 
//         header:"Cet email déjà utilisé.", 
//         text:"Merci de choisir un autre email"} });
//   }

// }

// export function authError(error) {
//   return {
//     type: AUTH_ERROR,
//     payload: error
//   };
// }

export const signout = (cb=()=>{}) => async dispatch =>{
  try{
    const response = await apiServer.post('/signout');
    history.push("/");
    dispatch({ type: SELECT_EMAIL, payload: {} });
    dispatch({ type: SELECT_SUBSCRIPTION, payload:{} });
    dispatch({ type: UNAUTH_USER, payload: response.data });
    cb();
  } catch (e) {
    dispatch({ type: AUTH_ERROR, payload: "Erreur dans le signout" })
  //   dispatch({ type: SET_INFO_MESSAGE, payload: {type:'error', 
  //       header:"Erreur", 
  //       text:"Nous n'avons pas pu vous déconnecter correctement. Merci de réessayer."} });
  }

}

export const getUserInfo = (cb=()=>{}) => async dispatch => {
  try{
    const response = await apiServer.get('/userInfo', {timeout:5000});
    dispatch({ type: AUTH_USER, payload: response.data });
    cb();
  } catch (e) {
    dispatch({ type: AUTH_ERROR, payload: "Erreur dans la récupération des infos utilisateur" });
  }
}

