import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {fetchEmail, fetchEmailAndSelectSubscription} from '../../actions/emailActions';
import {selectSubscription} from '../../actions/subscriptionActions';
import root from 'react-shadow';
import history from '../../utilities/history';
import Header from './Header';


class EmailShow extends Component {
    componentDidMount(){
        // path /emails/:id --> on fetche les subs et on selectionne la sub de l'email (il faut d'abord que l'email ait été récupéré)
        this.props.fetchEmailAndSelectSubscription(this.props.match.params.publicEmailId);
    }
    componentDidUpdate(prevProps){
    }

    render() {
        return (
            <Fragment>
                <Header text={this.props.headerText} />
                <div className="row"><button className="ui button" onClick={history.goBack}><i className="arrow left icon"></i> Back</button></div>
                <root.div id="emailRoot">
                    <div id="email" dangerouslySetInnerHTML={{ __html: this.props.selectedEmail.html }}></div>
                </root.div>
                <div className="row"><button className="ui button" onClick={history.goBack}><i className="arrow left icon"></i> Back</button></div>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return { 
        selectedEmail: state.selectedEmail,
        headerText: state.headerText 
    }
}

export default connect(mapStateToProps, {fetchEmail,fetchEmailAndSelectSubscription, selectSubscription})(EmailShow);