import React, { Component, Fragment } from 'react';
import EmailItem from './emailItem';


const EmailList = ({ emails }) => {
    const renderList = (emails) => {
        return emails.map(email => {
            return (
                <EmailItem email={email} key={`${email.publicEmailId}`} />
            );
        });
    }


    return (
        <Fragment>
            {renderList(emails)}
        </Fragment>
    );
}

export default EmailList;


