import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment';

const EmailItem = ({ email }) => {
    const { subject, date, isRead,  publicEmailId , fromText} = email;


    return (
        <div className="item">
            <div className="ui mini image">
                <img src="https://semantic-ui.com/images/wireframe/image.png" />
            </div>
            <div className="content">
                <div className="header">
                    <Link to={`/emails/${publicEmailId}`}
                        style={isRead ? { fontWeight: 100, color: "#77777777" } : { fontWeight: 900 }}>
                        {subject}
                    </Link>
                </div>
                <div className="meta">
                    <span className="date">{moment(date).fromNow()}</span><span> | {fromText}</span>
                </div>
                {/* <div className="description">
                    <p>{fromText}</p>
                </div> */}
            </div>
        </div>
    );
}

export default EmailItem;