import React from 'react';
import { NavLink } from 'react-router-dom';


const SubscriptionItem = ({ subscription, active }) => {
    const {  fromText, publicSubscriptionId, unreadCount} = subscription;
    const maxLength=25;
    return (
        
        <NavLink
            className="item"
            activeClassName="active"
            to={`/subscriptions/${publicSubscriptionId}`}
            isActive={(match, location)=>{return active}}
            >
            {fromText.length> maxLength ? fromText.substring(0, maxLength)+"..." : fromText}
            {unreadCount !== 0 && <div className="ui circular label">{unreadCount}</div>}
        </NavLink>
    );
}

export default SubscriptionItem;