import {
    FETCH_EMAILS,
    SELECT_EMAIL,
    EMAIL_MARKED_AS_READ,
    UPDATE_SUBSCRIPTION,
    SET_HEADER_TEXT
} from './types';
import apiServer from '../apis/apiServer';
import { simpleParser } from 'mailparser';
import { fetchSubscriptionsAndSelect, selectSubscription } from './subscriptionActions';
import _ from 'lodash';

export const fetchEmails = (subId) =>
    (dispatch, getState) =>
        apiServer.get(`/subscriptions/${subId}/emails`)
            .then((response) => dispatch({ type: FETCH_EMAILS, payload: response.data }))
            .catch((err) => console.log(err));

export const fetchAllEmails = () =>
    (dispatch, getState) =>
        apiServer.get(`/emails/all`)
            .then((response) => dispatch({ type: FETCH_EMAILS, payload: response.data }))
            .then(dispatch(setHeaderText("All")))
            .catch((err) => console.log(err));

export const fetchUnreadEmails = () =>
    (dispatch, getState) =>
        apiServer.get(`/emails/unread`)
            .then((response) => dispatch({ type: FETCH_EMAILS, payload: response.data }))
            .then(dispatch(setHeaderText("Unread")))
            .catch((err) => console.log(err));

//appeler uniquement si on arrive directement dans l'app avec une url de la form /emails/:id
export const fetchEmail = (publicEmailId) =>
    (dispatch, getState) =>
        apiServer.get(`/emails/${publicEmailId}/`)
            .then((response) => Promise.all([
                // dispatch(selectSubscription(response.data[0].publicSubscriptionId)),
                dispatch(fetchEmailS3(response.data[0]))
            ]))
            .catch((error) => console.log(error));



export const fetchEmailS3 = (emailObj) =>
    (dispatch, getState) => {
        const { s3Bucket, s3File } = emailObj;
        return apiServer.get(`https://s3.eu-west-3.amazonaws.com/${s3Bucket}/${s3File}`,
            {
                responseType: 'text',
                withCredentials: false
            })
            .then((response) =>
                simpleParser(response.data, {})
                    .then(parsed => {
                        dispatch({ type: SELECT_EMAIL, payload: { ...emailObj, ...parsed } });
                        dispatch(markAsRead(emailObj));
                    })
                    .catch(err => console.log(err)))
            .catch((error) => console.log(error))
    }


export const fetchEmailAndSelectSubscription = (publicEmailId) =>
    (dispatch, getState) =>
        dispatch(fetchEmail(publicEmailId))
            .then(() => {
                if (Object.size(getState().subscriptions) == 0) {
                    dispatch(fetchSubscriptionsAndSelect(getState().selectedEmail.publicSubscriptionId))
                } else {
                    dispatch(selectSubscription(getState().selectedEmail.publicSubscriptionId))
                }

            })


export const markAsRead = (emailObj) =>
    (dispatch, getState) => {
        if (!emailObj.isRead) {
            return apiServer.patch(`/emails/${emailObj.publicEmailId}/`, { data: { isRead: 1 } })
                .then(() => {
                    dispatch({ type: EMAIL_MARKED_AS_READ, payload: { ...emailObj, isRead: 1 } })
                    const count = getState().selectedSubscription.unreadCount - 1;
                    const subscription = { ...getState().selectedSubscription, unreadCount: count };
                    dispatch({ type: UPDATE_SUBSCRIPTION, payload: subscription });
                })
                .catch((e) => console.log(e))
        }
    }

export const setHeaderText = (text) =>
    (dispatch) =>
        dispatch({ type: SET_HEADER_TEXT, payload: text })

export const deselectEmail = () =>
    ({ type: SELECT_EMAIL, payload: {} })
