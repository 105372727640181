import { combineReducers } from 'redux';
import { subscriptions, selectedSubscription } from './subscriptionReducer';
import { emails, selectedEmail, headerText } from './emailReducer';
import { auth } from './authReducer';


export default combineReducers({
    subscriptions : subscriptions,
    selectedSubscription : selectedSubscription,
    emails: emails,
    selectedEmail: selectedEmail,
    auth: auth,
    headerText: headerText
});