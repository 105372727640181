import {
    AUTH_USER,
    UNAUTH_USER,
    AUTH_ERROR
} from '../actions/types';

export const auth = (state = { isSignedIn: false, currentUser: {}, errorMessag: '' }, action) => {

    switch (action.type) {
        case AUTH_USER:
            return {
                isSignedIn: true,
                currentUser: action.payload,
                isLoading: false
            };
        case AUTH_ERROR:
            return { ...state, errorMessage: action.payload , isLoading: false}

        case UNAUTH_USER:
            return {
                isSignedIn: false,
                currentUser: {}
            };
        default: return state;
    }
} 